type UserProps = {
    image?: string;
    id: string;
    name?: string;
    initials: string;
    first_name?: string;
    last_name?: string;
};

export const sizes = {
    xl: "size-24 min-h-24 min-w-24",
    lg: "size-14 min-h-14 min-w-14",
    md: "size-8 min-h-8 min-w-8",
};

export interface AvatarInterface {
    user: UserProps;
    size?: keyof typeof sizes;
    src?: string;
}

export default function Avatar({ user, size = "md", src }: AvatarInterface) {
    if (user.image || src) {
        return (
            <img
                key={user.id}
                src={src || user.image}
                className={`inline-block ${sizes[size]} rounded-full ring-2 ring-white object-cover`}
                alt={user.name || ""}
            />
        );
    } else {
        return (
            <span
                className={`inline-flex ${sizes[size]} items-center justify-center rounded-full bg-gray-500 border border-gray-100`}
                key={user.id}
            >
                <span className="text-sm font-medium leading-none text-white">
                    {user.initials}
                </span>
            </span>
        );
    }
}
