import {
    ExclamationTriangleIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { router } from "@inertiajs/react";
import { FormattedMessage } from "react-intl";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { Collaborator } from "../../../types/collaborator";

interface CollaboratorOffboardBannerInterface extends Collaborator {
    remainingDuration: number;
}

export default function CollaboratorOffboardBanner({
    collaborator,
}: {
    collaborator: CollaboratorOffboardBannerInterface;
}) {
    dayjs.extend(advancedFormat);
    return (
        <div className="bg-red-50">
            <div className="flex px-8 py-4 justify-between items-center">
                <div className="flex items-center">
                    <ExclamationTriangleIcon className="size-6 text-red-800 mr-4 min-h-6 min-w-6" />
                    <span>
                        <FormattedMessage
                            id="Your organisation's license expires in <strong>:duration days</strong>. You will no longer be able to access your Voice Dashboard after <strong>:endDate</strong>."
                            values={{
                                duration: collaborator.remainingDuration,
                                strong: (children) => (
                                    <strong>{children}</strong>
                                ),
                                endDate: dayjs(
                                    collaborator.license_terminated_at
                                ).format("Do MMMM YYYY"),
                            }}
                        />
                    </span>
                </div>
                <div className="flex items-center shrink-0 ml-4">
                    <a
                        href={`mailto:${collaborator.primary_contact}`}
                        className="font-medium border rounded-lg border-red-800 hover:bg-red-100 text-red-800 px-4 py-2 "
                    >
                        <FormattedMessage id="Contact admin" />
                    </a>
                    <div>
                        <button
                            onClick={() =>
                                router.post(route("hide-offboard-banner"))
                            }
                        >
                            <XMarkIcon className="size-6 ml-4 mt-1 text-black z-10" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
